<template>
  <div class="edit_box">
    <div class="mrt20">
      <el-form ref="form" :model="selectForm" label-width="80px">
        <div class="flex">
          <el-form-item label="订单编号">
            <el-input v-model="selectForm.order_sn" placeholder="请输入订单编号" clearable></el-input>
          </el-form-item>
          <el-form-item label="物流单号">
            <el-input v-model="selectForm.logistics_no" placeholder="请输入物流单号" clearable></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model="selectForm.goods_name" placeholder="请输入商品名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="买家昵称">
            <el-input v-model="selectForm.nickName" placeholder="请输入买家昵称" clearable></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="售后服务">
            <el-select v-model="selectForm.refund_status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker v-model="foundTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime(foundTime)" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="付款时间">
            <el-date-picker v-model="businessTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime1(businessTime)" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getList(Object.assign(selectForm,pageInfo))">搜索订单</el-button>
          <el-popover placement="bottom" title="" width="200" trigger="manual" v-model="visible">
            <div class="flex flexcc">
              <el-button type="primary" @click="derive('导出')">导出</el-button>
              <el-button @click="derive('导出全部')">导出全部</el-button>
            </div>
            <el-button type="success" slot="reference" style="margin-left: 20px;" @click="visible = !visible">导出订单</el-button>
          </el-popover>
        </el-form-item>
      </el-form>

      <div class="mrt20 flex">
        <div class="mrr20 mrt10 white-space ">
          订单状态
        </div>
        <el-tabs v-model="selectForm.order_status" @tab-click="handleClick(selectForm.order_status)">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="待发货" name="1"></el-tab-pane>
          <el-tab-pane label="已发货" name="2"></el-tab-pane>
          <el-tab-pane label="成功的订单" name="3"></el-tab-pane>
          <el-tab-pane label="售后订单" name="5"></el-tab-pane>
          <el-tab-pane label="关闭的订单" name="6"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 表格 -->

      <div class="content">
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" :loading="exproting">
          <el-table-column type="selection" width="55" />
          <el-table-column width="1" class="box">
            <template slot-scope="scope">
              <div class="item flex">
                <div style="margin-left:5%">
                  订单编号：{{ scope.row.order_sn }}
                </div>
                <div style="margin-left:5%">
                  创建时间：{{ scope.row.created_at }}
                </div>
                <div style="margin-left:5%">
                  买家昵称：{{ scope.row.consignee }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品信息" width="300" class="box">
            <template slot-scope="scope">
              <div class="table_col" v-for="(i,index) in scope.row.order_goods" :key="index">
                <div class="flex align-center">
                  <div class="imgSty">
                    <el-image style="width: 70px; height: 70px" :src="i.cover" :preview-src-list="[i.cover]" fit="fill"></el-image>
                  </div>
                  <div class="info_describe">
                    <div class="mrb20">{{ i.goods_name }}</div>
                    <div>
                      {{ i.sku_name }}
                    </div>

                  </div>
                </div>
              </div>
              <!-- <div>订单备注：128901</div> -->

            </template>
          </el-table-column>

          <el-table-column min-width="100" label="单价">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
                <p>{{ item.price }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="数量">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
                <p>{{ item.goods_num }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="售后">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.order_goods" :key="index" class="table_col">
                <el-button type="text" @click="after_sale(item)">
                  <p v-if=" item.refund_status_desc == ''">&nbsp;&nbsp;</p>
                  <p>{{ item.refund_status_desc }}</p>
                </el-button>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="id" min-width="140" label="id">
          </el-table-column> -->
          <el-table-column prop="pay_status_name" min-width="140" label="交易状态" align="center">
          </el-table-column>
          <el-table-column prop="amount" valign="top" min-width="140" label="实收款" align="center">
            <template slot-scope="scope">
              <div class="flex-column justify-center">
                <div>￥ {{ scope.row.amount }}</div>
                <div> 快递费：￥{{ scope.row.delivery }}</div>
                <div v-if="scope.row.delivery_type == 0">包邮</div>
                <div v-if="scope.row.delivery_type == 1">到付</div>
                <div v-if="scope.row.delivery_type == 2">统一价格</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" min-width="200" label="订单备注" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="detail(scope.row.id)">订单详情</el-button>
              <el-button type="text" v-if="scope.row.order_logistics !== ''" size="small" @click="material_flow(scope.row.id)">物流信息</el-button>
              <el-button type="text" v-if="scope.row.status == 1" size="small" @click="goDeliver(scope.row)">去发货</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div> -->
        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 发货弹窗 -->
    <el-dialog title="提示" :visible.sync="shipmentsVisible" width="40%">
      <el-form ref="shipmentsForm" :model="shipmentsForm" label-width="80px">
        <el-form-item label="物流单号">
          <el-input v-model="shipmentsForm.logistics_no" style="width: 450px;"></el-input>
        </el-form-item>
        <el-form-item label="物流公司">
          <el-select v-model="shipmentsForm.logistics_type" placeholder="请选择" clearable style="width: 450px;">
            <el-option v-for="(item,i) in logisticsList" :key="i" :label="item.name" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shipmentsVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDeliverGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      popSty: false,
      visible: false,
      exproting: false,
      textarea: "",
      statusOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 5,
          label: "退款待处理",
        },
        {
          value: 6,
          label: "退款完成",
        },
      ],
      tableData: [],
      logisticsList: [],
      businessTime: "",
      foundTime: "",
      shipmentsVisible: false,
      shipmentsForm: {
        order_id: "",
        logistics_no: "",
        logistics_type: "",
      },
      params: {
        from: "",
        to: "",
        tab: "today",
      },
      selectForm: {
        refund_status: "",
        order_status: "0",
        order_sn: "",
        order_sn: "",
        goods_name: "",
        nickName: "",
        start_date: "",
        end_date: "",
        pay_time_start_date: "",
        pay_time_end_date: "",
        logistics_no: "",
        is_all: "",
        ids: [],
        is_export: "",
      },
      popSty: false,
      textarea: "",
      tableNewData: [],
      hoverOrderArr: [],
      OrderIndexArr: [],

      popSty: false,
      textarea: "",

      total: 0,
      currentPage: 1, //初始页
      pagesize: 10, //每页的数据
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
    };
  },

  created() {
    this.getList();
    this.getmaterialFlow();
  },
  mounted() {},
  computed: {},

  methods: {
    async getList() {
      this.selectForm.is_export = "";
      const { data } = await this.$Api.orderOrderList({
        ...this.pageInfo,
        ...this.selectForm,
      });
      this.tableData = data.list;

      console.log("订单列表", data);
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
    },
    async getmaterialFlow() {
      const { data } = await this.$Api.orderLogistic();
      this.logisticsList = data;
      // console.log("物流公司列表", data);
    },
    goDeliver(row) {
      this.shipmentsVisible = true;
      this.shipmentsForm.order_id = row.id;
    },
    async toDeliverGoods() {
      const data = await this.$Api.orderSend(this.shipmentsForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("发货成功");
      this.shipmentsVisible = false;
      this.getList(this.pageInfo);
    },
    handleSelectionChange(val) {
      this.selectForm.ids = val.map((item) => item.id);
      // console.log("多选框选择的值", this.selectForm.ids);
    },
    detail(id) {
      this.$router.push({
        path: "/order/orderDetail?id=" + id,
      });
    },
    material_flow(id) {
      this.$router.push({
        path: "/order/LogisticsInfo?id=" + id,
      });
    },
    after_sale(row) {
      this.$router.push({
        path: "/order/afterSale",
        query: {
          goods_id: row.goods_id,
          order_id: row.order_id,
        },
      });
    },
    handleClick(val) {
      this.selectForm.order_status = val;
      this.getList();
    },
    async derive(fild) {
      if (fild == "导出") {
        this.selectForm.is_all = 0;
        this.selectForm.is_export = 1;
        this.exproting = true;
        const { data } = await this.$Api.orderOrderList({
          ...this.pageInfo,
          ...this.selectForm,
        });
        console.log("data.data", data);
        this.exproting = false;

        window.location.href = data;
      }
      if (fild == "导出全部") {
        this.selectForm.is_all = 1;
        this.selectForm.is_export = 1;
        this.selectForm.ids = [];
        this.exproting = true;
        const { data } = await this.$Api.orderOrderList({
          ...this.pageInfo,
          ...this.selectForm,
        });
        this.exproting = false;
        window.location.href = data;
      }
    },
    getTime(value) {
      if (!value) {
        // 如果清空了时间选择 则清空时间筛选 获取今天的数据
        this.selectForm.start_date = "";
        this.selectForm.end_date = "";
        this.getList();
        return;
      }
      const [start_date, end_date] = value;
      // 对象合并 合并进params对象里
      Object.assign(this.selectForm, { start_date, end_date });
      // 把tabs清空
      this.getList();
    },
    getTime1(value) {
      if (!value) {
        this.selectForm.pay_time_start_date = "";
        this.selectForm.pay_time_end_date = "";
        this.getList();
        return;
      }
      const [pay_time_start_date, pay_time_end_date] = value;
      Object.assign(this.selectForm, {
        pay_time_start_date,
        pay_time_end_date,
      });
      this.getList();
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tabs--top {
  width: 100%;
}
.merchantOrderManage {
  background: #fff;
  width: 100%;
  left: 40px;
  right: 40px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .info_box {
    display: flex;
    align-items: center;
    margin: 0 10px;
    .info_box_img {
      cursor: pointer;
      width: 50px;
      flex: 0 0 50px;
      margin-right: 6px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .info_describe {
      .text {
        margin-bottom: 6px;
      }
      .attr {
        color: #9e9e9e;
        span {
          margin-right: 6px;
        }
      }
    }
  }
}

.el-tag {
  width: 100%;
  height: 36px;
  line-height: 36px;
}
.tip-tag {
  .el-tag {
    text-align: center;
  }
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>
<style scoped>
.content {
  padding: 20px;
}
.el-table--enable-row-transition /deep/ .cell {
  /* padding: 20px 0; */

  padding: 0;
  padding-top: 15px;
}
.item {
  width: 100vw;
  background: #f1f1f1;
  position: absolute;
  top: 0;
  left: -55px;
  z-index: 1;
  height: 30px;
  line-height: 30px;
}
</style>
<style scoped>
.imgSty {
  width: 100px;
  display: block;
  padding-left: 10px;
}
.table_col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #ebeef5;
}
.table_col:last-child {
  border-bottom: 1px solid transparent;
}

.content >>> .el-table--border td:nth-child(-n + 4) {
  border-right: none !important;
}
.content >>> .el-table--border td:nth-child(2) {
  border-left: 1px solid #ebeef5;
}
/* .content /deep/ .el-table__row td:nth-child(n + 6) .cell {
  position: absolute !important;
  top: 100px !important;
  left: 50%;
  transform: translateX(-50%);
} */
.content /deep/ .el-table__row td:nth-child(1) .cell {
  position: absolute !important;
  top: 1% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  /* background: red; */
}
.pb_sty span {
  background: #1dc8de;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
}

.mesFont p {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0 10px;
}
.mesFont p:nth-child(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* .el-table__row:hover .item {
  background-color: #eaf8ff;
} */
</style>